import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import index from '!!raw-loader!.';
import { Code } from 'gatsby-theme-docz/src/components/Code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "prettier-config"
    }}>{`Prettier config`}</h1>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm i @madebywild/prettier-config prettier --save-dev
`}</code></pre>
    <p>{`Then, edit your projects `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "prettier": "@madebywild/prettier-config"
}
`}</code></pre>
    <h2 {...{
      "id": "how-to-bump-version"
    }}>{`How to bump version`}</h2>
    <p>{`Install `}<inlineCode parentName="p">{`np`}</inlineCode>{` from `}<a parentName="p" {...{
        "href": "https://github.com/sindresorhus/np"
      }}>{`https://github.com/sindresorhus/np`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run release
`}</code></pre>
    <h2 {...{
      "id": "rules"
    }}>{`Rules`}</h2>
    <Code className="javascript" mdxType="Code">
  {index}
    </Code>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      